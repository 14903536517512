<template>
  <div>
    <section v-if="registerView" id="register">
      <v-form ref="form" @submit.prevent="register">
        <v-container class="register_container">
          <v-row class="d-flex flex-column justify-center align-center">
            <h3 class="register-card__header">Create an account</h3>
            <s-card v-if="isVerifying" class="login-card col-12" outlined>
              <h3 class="login-card__header align-center text-center">
                Push notification sent
              </h3>
              <s-text
                weight="normal"
                color="grayTextAlt"
                class="align-center text-center mb-4"
              >
                On your mobile device, open the <b>ReachOut</b> app verification
                prompt, then tap <b>Approve login</b> to continue.
              </s-text>
              <div class="block_loader d-flex align-center justify-center">
                <v-progress-circular
                  indeterminate
                  class="ma-auto"
                  color="primary"
                  :size="20"
                  :width="3"
                />
              </div>
            </s-card>
            <div class="max-width" v-else>
              <s-card class="register-card col-12" outlined>
                <v-row>
                  <v-col>
                    <v-row class="mb-n5">
                      <v-col>
                        <s-text-field
                          name="fname"
                          label="First Name"
                          type="text"
                          filled
                          required
                          v-model="value.fname"
                          :error-messages="
                            getErrors('text', $v.value.fname, 'First name')
                          "
                          @blur="$v.value.fname.$touch()"
                        >
                        </s-text-field>
                      </v-col>
                      <v-col>
                        <s-text-field
                          name="lname"
                          label="Last Name"
                          filled
                          type="text"
                          required
                          v-model="value.lname"
                          :error-messages="
                            getErrors('text', $v.value.lname, 'Last name')
                          "
                          @blur="$v.value.lname.$touch()"
                        >
                        </s-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="my-0">
                      <v-col>
                        <s-text-field
                          name="company"
                          type="text"
                          label="Company"
                          :error-messages="
                            getErrors('text', $v.value.company, 'Company')
                          "
                          @blur="$v.value.company.$touch()"
                          filled
                          required
                          v-model="value.company"
                        />
                      </v-col>
                    </v-row>
                    <v-row class="mt-n5">
                      <v-col>
                        <s-text-field
                          name="phone"
                          label="ReachOut Mobile Number"
                          filled
                          type="number"
                          required
                          v-model="value.phone"
                          :error-messages="getErrors('phone', $v.value.phone)"
                          @blur="$v.value.phone.$touch()"
                        >
                        </s-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <s-btn
                  testButton="submit-button"
                  type="submit"
                  color="primary"
                  class="ma-0"
                  elevation="0"
                  :loading="isLoading"
                  :disabled="$v.$invalid"
                  block
                >
                  Sign Up
                </s-btn>
              </s-card>
              <div class="pt-3 max-width">
                Already got an account ?
                <s-link class="forgotpass-link pl-1" :to="{ name: 'login' }">
                  Sign In
                </s-link>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>
    </section>
    <div style="height: 90vh" class="d-flex align-center" v-else>
      <register-success action="Registration" />
    </div>
  </div>
</template>

<script>
import { SimpleEventBus } from '@/services/core/EventBus'
import ioKeys from '@/store/auth.store'
import getErrors from '@/mixins/vuelidateErrors'
import { required } from 'vuelidate/lib/validators'
import RegisterSuccess from '@/components/auth/ResetPasswordSuccess.vue'
export default {
  mixins: [getErrors],
  name: 'Register',
  components: {
    'register-success': RegisterSuccess,
  },
  computed: {
    isVerifying() {
      return this.isLoading && this.isWaitingForVerification
    },
  },
  data() {
    return {
      registerView: true,
      value: {
        email: '',
        fname: '',
        lname: '',
        company: '',
        phone: '',
      },
      isLoading: false,
      isWaitingForVerification: false,
    }
  },
  validations: {
    value: {
      fname: { required },
      lname: { required },
      phone: { required },
      company: { required },
    },
  },
  methods: {
    resetUI() {
      this.isLoading = false
      this.isWaitingForVerification = false
    },
    register() {
      if (this.invalidForm) {
        this.$v.$touch()
        return
      }
      this.isLoading = true
      const payload = {
        fname: this.value.fname,
        lname: this.value.lname,
        organization: this.value.company,
        reachout_mobile: this.value.phone,
      }
      this.$store
        .dispatch('auth/initRegister', payload)
        .then((resp) => {
          // start websocket
          this.$store
            .dispatch('auth/initRegisterCompleteWebsocket')
            .then((socket) => {
              // send verification id
              socket.queue(resp.verification_id)
            })
          // this.registerView = false
          this.isWaitingForVerification = true
        })
        .catch(() => {
          this.resetUI()
        })
    },
  },
  destroyed() {
    // remove listener
    SimpleEventBus.$off(ioKeys.auth_login)
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';
@import '@/assets/styles/main.scss';
#register {
  @include center-children;

  & .register_container {
    @include auth-card-dimension;
  }
}

#logo {
  @include auth-logo;
}

.register-card {
  @include auth-card;
}

.register-card__header {
  @include auth-header;
}

.signup-link {
  margin-top: 18px;
}

.forgotpass-link {
  @include black-link;
  text-decoration: underline;
}
</style>
